/**
 * This file includes polyfills needed by Angular and is loaded before the app.
 * You can add your own extra polyfills to this file.
 *
 * This file is divided into 2 sections:
 *   1. Browser polyfills. These are applied before loading ZoneJS and are sorted by browsers.
 *   2. Application imports. Files imported after ZoneJS that should be loaded before your main
 *      file.
 *
 * The current setup is for so-called "evergreen" browsers; the last versions of browsers that
 * automatically update themselves. This includes Safari >= 10, Chrome >= 55 (including Opera),
 * Edge >= 13 on the desktop, and iOS 10 and Chrome on mobile.
 *
 * Learn more in https://angular.io/guide/browser-support
 */

/***************************************************************************************************
 * BROWSER POLYFILLS
 */

/**
 * By default, zone.js will patch all possible macroTask and DomEvents
 * user can disable parts of macroTask/DomEvents patch by setting following flags
 * because those flags need to be set before `zone.js` being loaded, and webpack
 * will put import in the top of bundle, so user need to create a separate file
 * in this directory (for example: zone-flags.ts), and put the following flags
 * into that file, and then add the following code before importing zone.js.
 * import './zone-flags.ts';
 *
 * The flags allowed in zone-flags.ts are listed here.
 *
 * The following flags will work for all browsers.
 *
 * (window as any).__Zone_disable_requestAnimationFrame = true; // disable patch requestAnimationFrame
 * (window as any).__Zone_disable_on_property = true; // disable patch onProperty such as onclick
 * (window as any).__zone_symbol__BLACK_LISTED_EVENTS = ['scroll', 'mousemove']; // disable patch specified eventNames
 *
 *  in IE/Edge developer tools, the addEventListener will also be wrapped by zone.js
 *  with the following flag, it will bypass `zone.js` patch for IE/Edge
 *
 *  (window as any).__Zone_enable_cross_context_check = true;
 *
 */

import './zone-flags';

/***************************************************************************************************
 * Zone JS is required by default for Angular itself.
 */

import 'zone.js'; // Included with Angular CLI.

/***************************************************************************************************
 * APPLICATION IMPORTS
 */
import 'intersection-observer';
import { addLeadingZero } from '@core/functions/addLeadingZero';

// https://tc39.github.io/ecma262/#sec-array.prototype.findindex
if (!Array.prototype.findIndex) {
  Object.defineProperty(Array.prototype, 'findIndex', {
    // eslint-disable-next-line
    value(predicate) {
      // 1. Let O be ? ToObject(this value).
      if (this == null) {
        throw new TypeError('"this" is null or not defined');
      }

      const o = Object(this);

      // 2. Let len be ? ToLength(? Get(O, "length")).
      // eslint-disable-next-line no-bitwise
      const len = o.length >>> 0;

      // 3. If IsCallable(predicate) is false, throw a TypeError exception.
      if (typeof predicate !== 'function') {
        throw new TypeError('predicate must be a function');
      }

      // 4. If thisArg was supplied, let T be thisArg; else let T be undefined.
      const thisArg = arguments[1];

      // 5. Let k be 0.
      let k = 0;

      // 6. Repeat, while k < len
      while (k < len) {
        // a. Let Pk be ! ToString(k).
        // b. Let kValue be ? Get(O, Pk).
        // c. Let testResult be ToBoolean(? Call(predicate, T, « kValue, k, O »)).
        // d. If testResult is true, return k.
        const kValue = o[k];
        if (predicate.call(thisArg, kValue, k, o)) {
          return k;
        }
        // e. Increase k by 1.
        k++;
      }

      // 7. Return -1.
      return -1;
    },
    configurable: true,
    writable: true
  });
}

declare global {
  interface Array<T> {
    /** sort by function */
    sortBy(func: (x: T) => number): T[];
    /** sort by key */
    // eslint-disable-next-line @typescript-eslint/unified-signatures
    sortBy(prop: string): T[];
    /** get last item from array */
    last(): T;
    /** get all distinct values */
    distinct(): T[];
    /** get all indexes */
    emptyEntries(length: number): number[][];
  }

  // eslint-disable-next-line id-blacklist
  interface String {
    /** Lowercase string then capitalize */
    capitalize(): string;
    /** Replace all */
    replaceAll(str: string | RegExp, newString: string): string;
    /** Matches a string with a regular expression, and returns an iterable of matches containing the results of that search. */
    matchAll(str: string | RegExp): IterableIterator<RegExpMatchArray>;
  }

  interface Date {
    /** calculte diff betwen date and given date in days */
    daysDiff(date: Date): number;
    /** calculte diff betwen date and given date in days */
    secondsDiff(date: Date): number;
    /** calculte diff betwen date and given date in days */
    minutesDiff(date: Date): number;
    /** check if date is today */
    isToday(): boolean;
    /** check if date is between two given dates */
    isBetween(start: Date, end: Date): boolean;
    /** check if date is valid */
    isValid(): boolean;
    /** check if date is same as given */
    isSame(date: Date): boolean;
    /** check if date is same as given */
    isSameDate(date: Date): boolean;
    /** check if date before given date */
    isBefore(date: Date): boolean;
    /** check if date after given date */
    isAfter(date: Date): boolean;
    /** Add seconds to date */
    addSeconds(seconds: number): Date;
    /** Add minutes to date */
    addMinutes(minutes: number): Date;
    /** Add days to date */
    addDays(days: number): Date;
    /** Add month to date */
    addMonths(months: number): Date;
    /** Add years to date */
    addYears(years: number): Date;
    /** Get string in format YYYY-MM-DD as iso date standard */
    toISODateString(): string;
    /** Get string in format YYYY-MM-DD in locale timezone */
    toLocaleISODateString(): string;
  }
}

Array.prototype.sortBy = function (prop): [] {
  return this.sort((a, b) => {
    let x;
    let y;
    if (typeof prop === 'function') {
      x = prop(a);
      y = prop(b);
    } else {
      x = a[prop];
      y = b[prop];
    }

    return x < y ? -1 : x > y ? 1 : 0;
  });
};

Array.prototype.last = function () {
  return this[this.length - 1];
};

Array.prototype.distinct = function () {
  return [...new Set(this)];
};

Array.prototype.emptyEntries = function (length: number) {
  return Array.from(Array(length).entries());
};

/**
 * String.prototype.replaceAll() polyfill
 * https://gomakethings.com/how-to-replace-a-section-of-a-string-with-another-one-with-vanilla-js/
 *
 * @author Chris Ferdinandi
 * @license MIT
 */
if (!String.prototype.replaceAll) {
  String.prototype.replaceAll = function (str: string, newStr: string): string {
    // If a regex pattern
    if (
      Object.prototype.toString.call(str).toLowerCase() === '[object regexp]'
    ) {
      return this.replace(str, newStr);
    }

    // If a string
    return this.replace(new RegExp(str, 'g'), newStr);
  };
}

// if (!String.prototype.matchAll) {
String.prototype.matchAll = function (
  rx: string | RegExp
): IterableIterator<RegExpMatchArray> {
  if (typeof rx === 'string') {
    rx = new RegExp(rx, 'g');
  } // coerce a string to be a global regex
  rx = new RegExp(rx); // Clone the regex so we don't update the last index on the regex they pass us
  let cap = []; // the single capture
  const all = []; // all the captures (return this)
  while ((cap = rx.exec(this)) !== null) {
    all.push(cap);
  } // execute and add
  return all as any; // profit!
};
// }

String.prototype.capitalize = function (): string {
  return this.charAt(0).toUpperCase() + this.toLowerCase().slice(1);
};

Date.prototype.daysDiff = function (date: Date): number {
  return Math.floor((date.getTime() - this.getTime()) / (24 * 3600 * 1000));
};

Date.prototype.secondsDiff = function (date: Date): number {
  return Math.floor((date.getTime() - this.getTime()) / 1000);
};

Date.prototype.minutesDiff = function (date: Date): number {
  return Math.floor((date.getTime() - this.getTime()) / (1000 * 60));
};

Date.prototype.isToday = function (): boolean {
  return (
    new Date().getFullYear() === this.getFullYear() &&
    new Date().getMonth() === this.getMonth() &&
    new Date().getDate() === this.getDate()
  );
};

Date.prototype.isBetween = function (start: Date, end: Date): boolean {
  return this.getTime() <= end.getTime() && this.getTime() >= start.getTime();
};

Date.prototype.isValid = function (): boolean {
  return !isNaN(this.getTime());
};

Date.prototype.isSame = function (date: Date): boolean {
  return date.isBetween(this, this);
};

Date.prototype.isSameDate = function (date: Date): boolean {
  return date.toDateString() === this.toDateString();
};
Date.prototype.isBefore = function (date: Date): boolean {
  return this.getTime() < date.getTime();
};

Date.prototype.isAfter = function (date: Date): boolean {
  return this.getTime() > date.getTime();
};

Date.prototype.addSeconds = function (seconds: number): Date {
  const date = new Date(this.valueOf());
  date.setSeconds(date.getSeconds() + seconds);
  return date;
};
Date.prototype.addMinutes = function (minutes: number): Date {
  const date = new Date(this.valueOf());
  date.setMinutes(date.getMinutes() + minutes);
  return date;
};

Date.prototype.addDays = function (days: number): Date {
  const date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

Date.prototype.addMonths = function (months: number): Date {
  const date = new Date(this.valueOf());
  date.setMonth(date.getMonth() + months);
  return date;
};

Date.prototype.addYears = function (years): Date {
  const date = new Date(this.valueOf());
  date.setFullYear(date.getFullYear() + years);
  return date;
};

Date.prototype.toISODateString = function (): string {
  return this.toISOString().slice(0, 10);
};

Date.prototype.toLocaleISODateString = function (): string {
  return `${this.getFullYear()}-${addLeadingZero(
    this.getMonth() + 1
  )}-${addLeadingZero(this.getDate())}`;
};
